import React from 'react';

const rules = {
    common: [],
    classic: [],
    tbc: [
        {
            title: 'Guild rank',
            content: (
                <React.Fragment>
                    <ul>
                        <li>Raider - zkuseny a kvalitni hrac, chodi vzdy pripraven, spolehlivy</li>
                        <li>Member - plnohodnotny clen guildy, ktery ale v nekterych ohledech nedosahuje na raidera</li>
                        <li>Novice - novacek guildy</li>
                    </ul>
                </React.Fragment>
            )
        },
        {
            title: '25-man raidy',
            content: (
                <React.Fragment>
                    <ul>
                        <li>Behem P1 budeme pokracovat v loot systemu MS > OS (+1), loot council se zavede az na (nebo tesne pred) P2</li>
                        <li>Raider i member maji prednost na loot pred Novice</li>
                        <li>RL/officer muze behem raidu, za vazne faily, udelit ban loot na ten dany raid vecer</li>
                        <li>Kazdy uz mel dostatek casu si vydelat goldy a na raidy budeme vyzadovat 100% pripravu ve forme spravnych enchantu, gemu, flasek (na BiSovem itemu, BiSove enchanty a gemy. Pokud to neni nekdo schopen splnit, tak nam to musi oduvodnit. Pripraveni lide na lavicce maji vzdy prednost pred nepripravenym clovekem v raidu</li>
                    </ul>
                </React.Fragment>
            )
        },
        {
            title: 'Raidy',
            content: (
                <React.Fragment>
                    <ul>
                        <li>Prihlasujte se na raidy take co nejdrive, tim se docili toho, ze ve stredu (na zacatku ID) se vytvori rostery a uz budeme vsichni na tyden dopredu vedet jak na tom jsme a kdy jdeme jaky raid (KZ i 25). Pozdni prihlaseni pravdepodobne zpusobi, ze se nedostanete na raid (Prihlasovanim myslim samozrejme i indikaci, ze nemuzete, pripadne jeste nevite - vsechny moznosti v kalendari jsou!)</li>
                        <li>Budte rdy na inv do raidu alespon 15 min pred oficialnim zacatkem</li>
                        <li>Neomluvena absence je prinejmensim zavaznym porusenim vaseho kodexu spolehlivosti, mind that</li>
                    </ul>
                </React.Fragment>
            )
        },
    ],
};

export default rules;
