import rules from "./rules";
import consumableRules from './consumableRules';
import headerLogo from './logo.png';
import Homepage from "./pages/Homepage";
import LinksPage from "./pages/LinksPage";

const config = {
  title: 'DeathBringers',
  domain: 'deathbringers.cz',
  language: 'cs',
  modes: ['wotlk'],
  faction: 'horde',
  modules: {
    HOMEPAGE: {
      customComponent: Homepage,
      particles: false,
    },
    GALLERY: {
      enabled: true,
    },
    GUILD_BANK: {
      enabled: false,
      url: ''
    },
    RULES: {
      enabled: true,
      rules,
      showConsumables: true,
    },
    CONSUMABLES: {
      enabled: false,
      consumableRules,
    },
    APPLICATIONS: {
      enabled: true,
      questions: [
        'spec',
        'race',
        'lvl',
        'professions',
        'wb',
        'ironforgeLink',
        'expectations',
        'experience',
        'offer',
      ]
    },
    CALENDAR: {
      enabled: true,
    },
    RAID_ROSTER: {
      enabled: true,
    },
    TWITCH: {
      enabled: true,
    },
    CUSTOM_PAGES: [
      {
        link: '/links',
        label: 'Odkazy',
        requiredRole: 'Novice',
        enabled: true,
        component: LinksPage,
      }
    ]
  },
  firebase: {
    apiKey: "AIzaSyAKGTCbfmPQMWRGGZwnADG851wIUrGWz4g",
    authDomain: "deathbringers.firebaseapp.com",
    projectId: "deathbringers",
    storageBucket: "deathbringers.appspot.com",
    messagingSenderId: "659142648024",
    appId: "1:659142648024:web:912d2ead0d931535b337ad",
    measurementId: "G-JZL7XQ48KV"
  },
  functionsUrl: {
    local: 'http://localhost:5001/deathbringers/us-central1',
    remote: 'https://us-central1-deathbringers.cloudfunctions.net',
  },
  theme: {
    template: 'DARK',
    colorPrimary: '#ff4a4a',
    headerLogo,
  },
  discord: {
    link: 'https://discord.gg/kZBsyqeSSZ',
    roles: {
      order: [
        'Admin',
        'Officer',
        'Raid Leader',
        'Master',
        'Raider',
        'Member',
        'Novice',
      ],
      MEMBER: 'Novice',
      OFFICER: 'Master',
    }
  },
  recaptcha: '6LcT3rIaAAAAAPGFKmAEiIbQZsnqoETxyoGMyrrV',
  bugsnag: {
    apiKey: '7a2967fb3a492daddf3097c4ae776afd',
  }
};

export default config;
