import React from 'react';

import 'wow-guild-web/styles/style.scss'
import 'wow-guild-web/styles/applications.scss'
import 'wow-guild-web/styles/consumables.scss'
import 'wow-guild-web/styles/gallery.scss'
import 'wow-guild-web/styles/guildbank.scss'
import 'wow-guild-web/styles/Profile.scss'
import 'wow-guild-web/styles/raidcalendar.scss'
import 'wow-guild-web/styles/rules.scss'
import 'wow-guild-web/styles/twitch.scss'

import config from './config';
import { createWowGuildApp } from 'wow-guild-web'

const appVersion = require('../package.json').version;

createWowGuildApp({
    config,
    appVersion,
});
