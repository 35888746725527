const ConsumableRules = {
    COMMON: {
        WARLOCK: {
            Base: {
                watched: {
                    28540: 0.8, // flask of pure death
                    17628: 0.6, // flask supreme power
                    46837: 0.8, // shattrath flask of pure death

                    28503: 0.5, // shadow elixir
                    28501: 0.5, // fire elixir
                    33721: 0.3, // adepts elixir
                    17539: 0.3, // greater arcane

                    39627: 0.3, // draeneic wisdom (int)
                    39625: 0.3, // major fortitude

                    33263: 0.2, // SP jidlo
                },
                substitutes: [
                    [28540, 46837, 17628], // flask
                    [28503, 28501, 33721, 17539], // battle
                    [39627, 39625], // guardian
                ]
            },
        },
        MAGE: {
            Base: {
                watched: {
                    28540: 0.8, // flask of pure death
                    28521: 0.8, // flask of blinding light
                    17628: 0.6, // flask supreme power
                    46837: 0.8, // shattrath flask of pure death
                    46839: 0.8, // shattrath flask of blinding light

                    28501: 0.5, // fire elixir
                    28493: 0.5, // frost elixir
                    33721: 0.3, // adepts elixir
                    17539: 0.3, // greater arcane

                    39627: 0.3, // draeneic wisdom (int)
                    39625: 0.3, // major fortitude

                    33263: 0.2, // SP jidlo
                },
                substitutes: [
                    [28540, 28521, 46837, 46839, 17628], // flask
                    [28501, 28493, 33721, 17539], // battle
                    [39627, 39625], // guardian
                ]
            },
        },
        SHAMAN: {
            Healer: {
                watched: {
                    28519: 0.5,  // Flask of Mighty Restoration
                    41610: 0.5, // Shattrath Flask of Mighty Restoration

                    28491: 0.45, // healing power
                    33721: 0.2, // adepts

                    28509: 0.2, // Elixir of Major Mageblood
                    39627: 0.2, // Elixir of Draenic Wisdom

                    33268: 0.35, // fish sticks
                    33265: 0.2, //sporefish
                },
                substitutes: [
                    [28519, 41610],
                    [28491, 33721],
                    [28509, 39627],
                    [33268, 33265],
                ]
            },
            Base: {
                watched: {
                    28521: 0.8, // flask of blinding light
                    17628: 0.6, // flask supreme power
                    46839: 0.8, // shattrath flask of blinding light

                    33721: 0.3, // adepts elixir
                    17539: 0.3, // greater arcane

                    39627: 0.3, // draeneic wisdom (int)
                    39625: 0.3, // major fortitude

                    33263: 0.2, // SP jidlo
                },
                substitutes: [
                    [28521, 46839, 17628], // flask
                    [33721, 17539], // battle
                    [39627, 39625], // guardian
                ]
            },
        },
        PRIEST: {
            Healer: {
                watched: {
                    28519: 0.5,  // Flask of Mighty Restoration
                    41610: 0.5, // Shattrath Flask of Mighty Restoration

                    28491: 0.45, // healing power
                    33721: 0.2, // adepts

                    28509: 0.2, // Elixir of Major Mageblood
                    39627: 0.2, // Elixir of Draenic Wisdom

                    33268: 0.35, // fish sticks
                    33265: 0.2, //sporefish
                },
                substitutes: [
                    [28519, 41610],
                    [28491, 33721],
                    [28509, 39627],
                    [33268, 33265],
                ]
            },
            Base: {
                watched: {
                    28540: 0.8, // flask of pure death
                    17628: 0.6, // flask supreme power
                    46837: 0.8, // shattrath flask of pure death

                    28503: 0.5, // shadow elixir
                    33721: 0.3, // adepts elixir
                    17539: 0.3, // greater arcane

                    39627: 0.3, // draeneic wisdom (int)
                    39625: 0.3, // major fortitude

                    33263: 0.2, // SP jidlo
                },
                substitutes: [
                    [28540, 46837, 17628], // flask
                    [28503, 33721, 17539], // battle
                    [39627, 39625], // guardian
                ]
            },
        },
        DRUID: {
            Healer: {
                watched: {
                    28519: 0.5,  // Flask of Mighty Restoration
                    41610: 0.5, // Shattrath Flask of Mighty Restoration

                    28491: 0.45, // healing power
                    33721: 0.2, // adepts

                    28509: 0.2, // Elixir of Major Mageblood
                    39627: 0.2, // Elixir of Draenic Wisdom

                    33268: 0.35, // fish sticks
                    33265: 0.2, //sporefish
                },
                substitutes: [
                    [28519, 41610],
                    [28491, 33721],
                    [28509, 39627],
                    [33268, 33265],
                ]
            },
            Tank: {
                watched: {
                    28518: 0.8, // Flask of Fortification
                    41609: 0.8, // 'Shattrath Flask of Fortification'

                    33726: 0.2, // mastery
                    28497: 0.2, // agility

                    28502: 0.5, // major defense
                    39626: 0.4, // earthen
                    39625: 0.4, // major fortitude

                    33257: 0.2, // fisherman
                    35272: 0.1, //talbuk
                    45245: 0.1, // hot apple
                    33254: 0.1, // buzzard
                    33261: 0.05, // mudfish
                },
                substitutes: [
                    [28518, 41609],
                    [33726, 28497],
                    [28502, 39626, 39625],
                    [33257, 35272, 45245, 33254, 33261],
                ]
            },
            Base: {
                watched: {
                    28521: 0.8, // flask of blinding light
                    17628: 0.6, // flask supreme power
                    46839: 0.8, // shattrath flask of blinding light

                    33721: 0.3, // adepts elixir
                    17539: 0.3, // greater arcane

                    39627: 0.3, // draeneic wisdom (int)
                    39625: 0.3, // major fortitude

                    33263: 0.2, // SP jidlo
                },
                substitutes: [
                    [28521, 46839, 17628], // flask
                    [33721, 17539], // battle
                    [39627, 39625], // guardian
                ]
            },
        },
        WARRIOR: {
            Tank: {
                watched: {
                    28518: 0.8, // Flask of Fortification
                    41609: 0.8, // 'Shattrath Flask of Fortification'

                    33726: 0.2, // mastery
                    28497: 0.2, // agility

                    28502: 0.5, // major defense
                    39626: 0.4, // earthen
                    39625: 0.4, // major fortitude

                    33257: 0.2, // fisherman
                    35272: 0.1, //talbuk
                    45245: 0.1, // hot apple
                    33254: 0.1, // buzzard
                    33261: 0.05, // mudfish
                },
                substitutes: [
                    [28518, 41609],
                    [33726, 28497],
                    [28502, 39626, 39625],
                    [33257, 35272, 45245, 33254, 33261],
                ]
            },
            Base: {
                watched: {
                    28520: 0.8, // Flask of Relentless Assault
                    41608: 0.8, // Shattrath Flask of Relentless Assault

                    28497: 0.4, // elixir agi
                    11406: 0.7, // demonslaying

                    39625: 0.1, // fortitude,

                    33256: 0.2, // clefthoof
                    33259: 0.2, // ravager
                    43764: 0.2, // talbuk
                },
                substitutes: [
                    [28520, 41608],
                    [28497, 11406],
                    [33256, 33259, 43764],
                ]
            },
        },
        ROGUE: {
            Base: {
                watched: {
                    28520: 0.8, // Flask of Relentless Assault
                    41608: 0.8, // Shattrath Flask of Relentless Assault

                    28497: 0.7, // elixir agi
                    11406: 0.75, // demonslaying

                    39627: 0.05, // draeneic wisdom

                    33261: 0.2, // grilled mudfish
                    43764: 0.2, // Spicy Hot Talbuk
                    33259: 0.2, // ravager
                },
                substitutes: [
                    [28520, 41608],
                    [28497, 11406],
                    [33261, 43764, 33259],
                ]
            },
        },
        HUNTER: {
            Base: {
                watched: {
                    28520: 0.8, // Flask of Relentless Assault
                    41608: 0.8, // Shattrath Flask of Relentless Assault

                    28497: 0.7, // elixir agi
                    11406: 0.75, // demonslaying

                    39627: 0.05, // draeneic wisdom

                    33261: 0.2, // grilled mudfish
                    43764: 0.2, // Spicy Hot Talbuk
                    33259: 0.2, // ravager
                },
                substitutes: [
                    [28520, 41608],
                    [28497, 11406],
                    [33261, 43764, 33259],
                ]
            },
        },
        PALADIN: {
            Tank: {
                watched: {
                    28518: 0.8, // Flask of Fortification
                    41609: 0.8, // 'Shattrath Flask of Fortification'

                    33726: 0.2, // mastery
                    28497: 0.2, // agility

                    28502: 0.5, // major defense
                    39626: 0.4, // earthen
                    39625: 0.4, // major fortitude

                    33263: 0.2, // basilisk
                    33257: 0.2, // fisherman
                    35272: 0.1, //talbuk
                    45245: 0.1, // hot apple
                    33254: 0.1, // buzzard
                    33261: 0.05, // mudfish
                },
                substitutes: [
                    [28518, 41609],
                    [33726, 28497],
                    [28502, 39626, 39625],
                    [33263, 33257, 35272, 45245, 33254, 33261],
                ]
            },
            Healer: {
                watched: {
                    28519: 0.5,  // Flask of Mighty Restoration
                    41610: 0.5, // Shattrath Flask of Mighty Restoration

                    28491: 0.45, // healing power
                    33721: 0.2, // adepts

                    28509: 0.2, // Elixir of Major Mageblood
                    39627: 0.2, // Elixir of Draenic Wisdom

                    33268: 0.35, // fish sticks
                    33265: 0.2, //sporefish
                },
                substitutes: [
                    [28519, 41610],
                    [28491, 33721],
                    [28509, 39627],
                    [33268, 33265],
                ]
            },
            Base: {
                watched: {
                    28520: 0.8, // Flask of Relentless Assault
                    41608: 0.8, // Shattrath Flask of Relentless Assault

                    28497: 0.4, // elixir agi
                    11406: 0.7, // demonslaying

                    39625: 0.1, // fortitude,
                    28509: 0.1, // mageblood

                    33256: 0.2, // clefthoof
                    33259: 0.2, // ravager
                    43764: 0.2, // talbuk
                },
                substitutes: [
                    [28520, 41608],
                    [28497, 11406],
                    [39625, 28509],
                    [33256, 33259, 43764],
                ]
            },
        }
    },
};

export default ConsumableRules;
