import React from 'react';
import {useMediaQuery, useTheme} from "@material-ui/core";
import '../styles/homepage-style.scss';

const AnimatedRow = ({main, right}) => {
	const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
	const theme = useTheme();

	return (
		<div className={`animated-row ${isMobile ? 'mobile' : ''}`}>
			<div className="main" style={{ color: theme.palette.primary.main }}>{main}&nbsp;</div>
			{right && <div className="right"><span>{right}</span></div>}
		</div>
	);
}

const Homepage = () => {
	return (
		<div className="homepage-wrapper">
			<div className="homepage">
				<AnimatedRow main="CZ/SK" right="horde guilda" />
				<AnimatedRow main="Twinstar" right="je naše bývalé působiště" />
				<AnimatedRow main="Jádro guildy" right="spolu hraje již přes 12 let" />
				<AnimatedRow main="Raid dny" right="čtvrtek a neděle 19-23" />
			</div>
		</div>
	);
};

export default Homepage;
