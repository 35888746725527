import React from 'react';
import {Paper} from "@material-ui/core";
import { Anchor } from 'wow-guild-web'

const Row = ({link, label}) => (
	<div style={{ margin: '0.5rem', textAlign: 'center'}}>
		<Anchor href={link} target="_blank">{label}</Anchor>
	</div>
);

const Space = () => <div style={{margin: '2rem'}}/>

const LinksPage = () => {
	return (
		<Paper
			style={{
				padding: '1rem',
				margin: '1rem',
				fontSize: '1.2rem',
				// textTransform: 'uppercase'
			}}
		>
			<Row label="Assignmenty" link="https://docs.google.com/spreadsheets/d/e/2PACX-1vS6WJxb4vxpIlxvVp2lZv8ivCauxlUW6K5izGf6EGmGOzQ3MU8MzI0KOpYG1-C6TRXLF2knnAmtLeUW/pubhtml" />
			<Row label="RC Loot Council" link="https://www.curseforge.com/wow/addons/rclootcouncil-classic" />
			<Space />
			<Row label="Alchymisti" link="https://docs.google.com/spreadsheets/d/1jKKzoslQPquUiDe6yB9uKKE8BwlfNDc34V4mHbxMmpQ/edit#gid=0" />
			<Row label="Ench + JWC" link="https://docs.google.com/spreadsheets/d/1iw5P_5LQF6qqth39u_YgjC9qZMyjV8AqTdqQIMODqBI/edit?usp=sharing" />
			<Space />
			<Row label="BiS Listy" link="https://wowtbc.gg/wotlk/bis-list" />
			<Row label="Taktiky" link="https://www.warcrafttavern.com/wotlk/" />
		</Paper>
	);
};

export default LinksPage;
